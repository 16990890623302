import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseConfig';
import { toast } from 'react-toastify';
import Navbar from './dashboard/Navbar';
import { useNavigate } from 'react-router-dom';

export default function AdminSubscriptions() {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [pendingSubscriptions, setPendingSubscriptions] = useState([]);
  const [rejectedSubscriptions, setRejectedSubscriptions] = useState([]);
  const [cancelledSubscriptions, setCancelledSubscriptions] = useState([]);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);
  const [payingSubscriptions, setPayingSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedTab, setSelectedTab] = useState('pendientes');

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
        if (session.user.email !== "tamayodaza9@gmail.com") {
          navigate('/inicio');
        } else {
          fetchSubscriptions();
        }
      } else {
        navigate('/login');
      }
    };
  
    getSession();
  
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
        if (session.user.email !== "tamayodaza9@gmail.com") {
          navigate('/inicio');
        } else {
          fetchSubscriptions();
        }
      } else {
        navigate('/login');
      }
    });
  
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Ordenar", href: "/registrar", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(isAdmin
      ? [
          { name: "Productos", href: "/productos", current: false },
          { name: "Gestionar Suscripciones", href: "/admin/subscriptions", current: true },
        ]
      : []),
  ];

  const userNavigation = [
    { name: 'Tu perfil', to: '/perfil' },
    { name: 'Configuraciones', href: '#' },
    { name: 'Cerrar sesión', onClick: handleLogout },
  ];

  const fetchSubscriptions = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('id, email, name, subscription');

      if (error) throw error;

      const activeSubs = data.filter(user => user.subscription && user.subscription.status === 'activo');
      const pendingSubs = data.filter(user => user.subscription && user.subscription.status === 'pendiente');
      const rejectedSubs = data.filter(user => user.subscription && user.subscription.status === 'rechazado');
      const cancelledSubs = data.filter(user => user.subscription && user.subscription.status === 'cancelado');
      const expiredSubs = data.filter(user => user.subscription && user.subscription.status === 'vencido');
      const payingSubs = data.filter(user => user.subscription && user.subscription.status === 'pagando');
      
      setActiveSubscriptions(activeSubs);
      setPendingSubscriptions(pendingSubs);
      setRejectedSubscriptions(rejectedSubs);
      setCancelledSubscriptions(cancelledSubs);
      setExpiredSubscriptions(expiredSubs);
      setPayingSubscriptions(payingSubs);
      setSubscriptions(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      toast.error('Error al cargar las suscripciones');
      setLoading(false);
    }
  };

  const handleVerifyPayment = async (userId, paymentId) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({
          'subscription': {
            'verified': true,
            'startDate': new Date().toISOString(),
            'endDate': new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
            'paymentId': paymentId,
            'requestDate': new Date().toISOString(),
            'status': 'activo'
          }
        })
        .eq('id', userId);

      if (error) throw error;

      toast.success('Suscripción activada correctamente');
      setPendingSubscriptions(pendingSubscriptions.filter(sub => sub.id !== userId));
      fetchSubscriptions();
    } catch (error) {
      console.error('Error verifying payment:', error);
      toast.error('Error al verificar el pago');
    }
  };

  const handleRejectPayment = async (userId) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({
          'subscription': {
            'paymentId': null,
            'status': 'rechazado'
          }
        })
        .eq('id', userId);

      if (error) throw error;

      toast.success('Suscripción rechazada correctamente');
      setPendingSubscriptions(pendingSubscriptions.filter(sub => sub.id !== userId));
      fetchSubscriptions();
    } catch (error) {
      console.error('Error rejecting payment:', error);
      toast.error('Error al rechazar el pago');
    }
  };

  const handleCancelSubscription = async (userId) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({
          'subscription': {
            'status': 'cancelado'
          }
        })
        .eq('id', userId);

      if (error) throw error;

      toast.success('Suscripción cancelada correctamente');
      setActiveSubscriptions(activeSubscriptions.filter(sub => sub.id !== userId));
      fetchSubscriptions();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      toast.error('Error al cancelar la suscripción');
    }
  };

  const renderSubscriptions = (subscriptions) => {
    return (
      <ul className="divide-y divide-gray-200">
        {subscriptions.map((subscription) => (
          <li key={subscription.id} className="p-4 hover:bg-gray-50">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-sm font-medium text-orange-600">
                  {subscription.email}
                </p>
                <p className="text-sm text-gray-500">
                  {subscription.name}
                </p>
                {subscription.subscription.paymentId && (
                  <p className="text-xs text-gray-400">
                    Referencia de pago: {subscription.subscription.paymentId}
                  </p>
                )}
                {subscription.subscription.requestDate && (
                  <p className="text-xs text-gray-400">
                    Fecha de solicitud: {new Date(subscription.subscription.requestDate).toLocaleString()}
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-4">
                {subscription.subscription.status === 'pendiente' && (
                  <>
                    <button
                      onClick={() => handleVerifyPayment(subscription.id, subscription.subscription.paymentId)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Verificar Pago
                    </button>
                    <button
                      onClick={() => handleRejectPayment(subscription.id)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Rechazar Pago
                    </button>
                  </>
                )}
                {subscription.subscription.status === 'activo' && (
                  <button
                    onClick={() => handleCancelSubscription(subscription.id)}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancelar Suscripción
                  </button>
                )}
              </div>
            </div>
          </li>
        ))}
        {subscriptions.length === 0 && (
          <li className="px-4 py-8 text-center text-gray-500">
            No hay suscripciones en esta categoría
          </li>
        )}
      </ul>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar
        user={user}
        navigation={navigation}
        userNavigation={userNavigation}
        handleLogout={handleLogout}
      />

      <div className="py-10">
        <header className="mb-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">Verificación de Pagos Nequi</h1>
          </div>
        </header>

        <main className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Suscripciones
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Gestiona las suscripciones de los usuarios
              </p>
            </div>

            <div className="px-4 py-5 sm:px-6">
              <div className="mb-4">
                <nav className="flex space-x-4 overflow-x-auto" aria-label="Tabs">
                  <button
                    onClick={() => setSelectedTab('pendientes')}
                    className={`relative inline-flex items-center px-3 py-2 font-medium text-sm rounded-md ${
                      selectedTab === 'pendientes' ? 'bg-orange-100 text-orange-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <span>Pendientes</span>
                    {pendingSubscriptions.length > 0 && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-600 text-white">
                        {pendingSubscriptions.length}
                      </span>
                    )}
                  </button>
                  
                  <button
                    onClick={() => setSelectedTab('activas')}
                    className={`relative inline-flex items-center px-3 py-2 font-medium text-sm rounded-md ${
                      selectedTab === 'activas' ? 'bg-orange-100 text-orange-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <span>Activas</span>
                    {activeSubscriptions.length > 0 && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-600 text-white">
                        {activeSubscriptions.length}
                      </span>
                    )}
                  </button>

                  <button
                    onClick={() => setSelectedTab('rechazadas')}
                    className={`relative inline-flex items-center px-3 py-2 font-medium text-sm rounded-md ${
                      selectedTab === 'rechazadas' ? 'bg-orange-100 text-orange-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <span>Rechazadas</span>
                    {rejectedSubscriptions.length > 0 && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-600 text-white">
                        {rejectedSubscriptions.length}
                      </span>
                    )}
                  </button>

                  <button
                    onClick={() => setSelectedTab('canceladas')}
                    className={`relative inline-flex items-center px-3 py-2 font-medium text-sm rounded-md ${
                      selectedTab === 'canceladas' ? 'bg-orange-100 text-orange-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <span>Canceladas</span>
                    {cancelledSubscriptions.length > 0 && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-600 text-white">
                        {cancelledSubscriptions.length}
                      </span>
                    )}
                  </button>

                  <button
                    onClick={() => setSelectedTab('vencidas')}
                    className={`relative inline-flex items-center px-3 py-2 font-medium text-sm rounded-md ${
                      selectedTab === 'vencidas' ? 'bg-orange-100 text-orange-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <span>Vencidas</span>
                    {expiredSubscriptions.length > 0 && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-600 text-white">
                        {expiredSubscriptions.length}
                      </span>
                    )}
                  </button>

                  <button
                    onClick={() => setSelectedTab('pagando')}
                    className={`relative inline-flex items-center px-3 py-2 font-medium text-sm rounded-md ${
                      selectedTab === 'pagando' ? 'bg-orange-100 text-orange-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <span>Pagando</span>
                    {payingSubscriptions.length > 0 && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-600 text-white">
                        {payingSubscriptions.length}
                      </span>
                    )}
                  </button>
                </nav>
              </div>

              {selectedTab === 'pendientes' && renderSubscriptions(pendingSubscriptions)}
              {selectedTab === 'activas' && renderSubscriptions(activeSubscriptions)}
              {selectedTab === 'rechazadas' && renderSubscriptions(rejectedSubscriptions)}
              {selectedTab === 'canceladas' && renderSubscriptions(cancelledSubscriptions)}
              {selectedTab === 'vencidas' && renderSubscriptions(expiredSubscriptions)}
              {selectedTab === 'pagando' && renderSubscriptions(payingSubscriptions)}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}