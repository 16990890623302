import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Landing() {
  const navigate = useNavigate();
  const NEQUI_NUMBER = "3112900929";

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const featureCardVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
    hover: {
      scale: 1.05,
      boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1)",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10,
      },
    },
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-orange-50 via-white to-orange-50">
      <motion.header
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
        className="bg-gradient-to-r from-orange-600 to-orange-500 p-6 shadow-lg"
      >
        <nav className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="text-4xl font-bold text-white mb-6 sm:mb-0"
          >
            YanbalPro
          </motion.div>
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 w-full sm:w-auto">
            <motion.div 
              whileHover={{ scale: 1.05 }} 
              whileTap={{ scale: 0.95 }}
              className="w-full sm:w-auto"
            >
              <Link
                to="/login"
                className="block w-full sm:w-auto px-8 py-3 text-orange-500 bg-white rounded-full hover:bg-orange-50 transition-all duration-300 text-center font-semibold shadow-lg hover:shadow-xl"
              >
                Iniciar sesión
              </Link>
            </motion.div>
            <motion.div 
              whileHover={{ scale: 1.05 }} 
              whileTap={{ scale: 0.95 }}
              className="w-full sm:w-auto"
            >
              <Link
                to="/register"
                className="block w-full sm:w-auto px-8 py-3 text-white border-2 border-white rounded-full hover:bg-orange-500 hover:text-white transition-all duration-300 text-center font-semibold shadow-lg hover:shadow-xl"
              >
                Registrarse
              </Link>
            </motion.div>
          </div>
        </nav>
      </motion.header>

      {/* Rest of the component remains unchanged */}
      <main className="flex-grow container mx-auto px-4 py-12 sm:py-20">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="text-center mb-16"
        >
          <motion.h1
            variants={itemVariants}
            className="text-5xl sm:text-7xl font-bold bg-gradient-to-r from-orange-600 to-orange-400 bg-clip-text text-transparent mb-8 leading-tight"
          >
            Bienvenido a YanbalPro
          </motion.h1>
          <motion.p
            variants={itemVariants}
            className="text-xl sm:text-2xl text-gray-600 mb-10 max-w-3xl mx-auto leading-relaxed"
          >
            Tu solución integral para la gestión de ventas y clientes en el
            mundo de la belleza y el cuidado personal.
          </motion.p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden mb-16 transform hover:scale-[1.02] transition-transform duration-300"
        >
          <div className="bg-gradient-to-r from-orange-600 to-orange-400 px-6 py-8 text-white text-center">
            <motion.h2
              variants={itemVariants}
              className="text-3xl font-bold mb-2"
            >
              Plan Premium
            </motion.h2>
            <motion.p
              variants={itemVariants}
              className="text-orange-100 text-lg"
            >
              Potencia tu negocio con características exclusivas
            </motion.p>
          </div>
          <div className="p-8">
            <motion.div
              variants={itemVariants}
              className="flex justify-center mb-12"
            >
              <div className="text-center">
                <span className="text-6xl font-bold bg-gradient-to-r from-orange-600 to-orange-400 bg-clip-text text-transparent">
                  $10.000
                </span>
                <span className="text-gray-500 ml-2 text-2xl">/mes</span>
              </div>
            </motion.div>

            <motion.div
              variants={containerVariants}
              className="grid md:grid-cols-2 gap-8 mb-12"
            >
              {[
                "Reportes avanzados de ventas",
                "Gestión de inventario ilimitada",
                "Soporte prioritario 24/7",
                "Exportación de datos en múltiples formatos",
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="flex items-center space-x-4 bg-orange-50 p-4 rounded-xl"
                >
                  <div className="flex-shrink-0 w-12 h-12 bg-gradient-to-r from-orange-600 to-orange-400 rounded-full flex items-center justify-center">
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-700 font-medium">{feature}</span>
                </motion.div>
              ))}
            </motion.div>

            <motion.div
              variants={itemVariants}
              className="bg-gradient-to-r from-orange-50 to-orange-100 p-8 rounded-2xl mb-8"
            >
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Instrucciones de Pago
              </h3>
              <ol className="space-y-4">
                <li className="flex items-center space-x-3">
                  <span className="flex-shrink-0 w-8 h-8 bg-orange-500 text-white rounded-full flex items-center justify-center font-bold">
                    1
                  </span>
                  <span>Envía $10.000 COP al siguiente número de Nequi:</span>
                </li>
                <li className="font-mono text-xl text-orange-600 font-bold pl-11">
                  {NEQUI_NUMBER}
                </li>
                <li className="flex items-center space-x-3">
                  <span className="flex-shrink-0 w-8 h-8 bg-orange-500 text-white rounded-full flex items-center justify-center font-bold">
                    2
                  </span>
                  <span>Guarda el comprobante de pago</span>
                </li>
                <li className="flex items-center space-x-3">
                  <span className="flex-shrink-0 w-8 h-8 bg-orange-500 text-white rounded-full flex items-center justify-center font-bold">
                    3
                  </span>
                  <span>Regístrate y activa tu cuenta premium</span>
                </li>
              </ol>
            </motion.div>

            <div className="text-center">
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  to="/register"
                  className="inline-block bg-gradient-to-r from-orange-600 to-orange-400 text-white px-12 py-4 rounded-full font-semibold text-lg hover:shadow-lg transform transition-all duration-300 hover:shadow-orange-200"
                >
                  Comenzar ahora
                </Link>
              </motion.div>
            </div>
          </div>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12"
        >
          {[
            {
              title: "Gestión de clientes",
              description:
                "Mantén un registro detallado de tus clientes, sus preferencias y su historial de compras para ofrecer un servicio personalizado.",
            },
            {
              title: "Control de pedidos",
              description:
                "Gestiona tus pedidos de manera eficiente, desde la creación hasta la entrega, con un sistema intuitivo y fácil de usar.",
            },
            {
              title: "Análisis de ventas",
              description:
                "Obtén insights valiosos sobre tus ventas y el rendimiento de tus productos para tomar decisiones informadas y potenciar tu negocio.",
            },
          ].map((feature, index) => (
            <motion.div
              key={index}
              variants={featureCardVariants}
              whileHover="hover"
              className="bg-white p-8 rounded-2xl shadow-lg border-t-4 border-orange-500"
            >
              <h2 className="text-2xl sm:text-3xl font-semibold bg-gradient-to-r from-orange-600 to-orange-400 bg-clip-text text-transparent mb-4">
                {feature.title}
              </h2>
              <p className="text-gray-600 text-lg leading-relaxed">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </motion.div>
      </main>

      <motion.footer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
        className="bg-gray-900 text-white py-12"
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="w-full sm:w-1/3 text-center sm:text-left mb-6 sm:mb-0">
              <h3 className="text-3xl font-bold bg-gradient-to-r from-orange-400 to-orange-600 bg-clip-text text-transparent mb-2">
                YanbalPro
              </h3>
              <p className="text-lg text-gray-300">
                Potenciando tu negocio de belleza
              </p>
            </div>
            <div className="w-full sm:w-1/3 text-center mb-6 sm:mb-0">
              <p className="text-lg text-gray-300">
                &copy; 2024 YanbalPro. Todos los derechos reservados.
              </p>
            </div>
            <div className="w-full sm:w-1/3 text-center sm:text-right space-x-6">
              <Link
                to="/about"
                className="text-orange-400 hover:text-orange-300 transition-colors duration-300 text-lg"
              >
                Sobre Nosotros
              </Link>
              <Link
                to="/contact"
                className="text-orange-400 hover:text-orange-300 transition-colors duration-300 text-lg"
              >
                Contacto
              </Link>
            </div>
          </div>
        </div>
      </motion.footer>
    </div>
  );
}

export default Landing;