import React from 'react';
import { motion } from 'framer-motion';

export default function StatCard({ title, value, icon, color = "bg-white", textColor = "text-gray-900" }) {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className={`${color} overflow-hidden rounded-xl shadow-lg transition-all duration-300`}
    >
      <div className="p-6">
        <div className="flex items-center">
          <div className="flex-shrink-0 mr-4 p-3 rounded-full bg-orange-100">
            {icon}
          </div>
          <div>
            <p className={`text-xl font-medium ${textColor}`}>{title}</p>
            <p className={`text-4xl font-bold ${textColor}`}>{value}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}