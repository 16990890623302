import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import {
  fetchClients,
  fetchOrders,
  fetchProducts,
} from '../services/supabaseService';
import { Bar, Doughnut } from 'react-chartjs-2';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import {
  UserIcon,
  ShoppingCartIcon,
  ClipboardDocumentListIcon,
  CubeIcon,
  ChartBarIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

// Import modular components
import Navbar from './dashboard/Navbar';
import StatCard from './dashboard/StatCard';
import RankingTable from './dashboard/RankingTable';
import ChartCard from './dashboard/ChartCard';
import HistoricalDataModal from './dashboard/HistoricalDataModal';


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [monthlyAbonos, setMonthlyAbonos] = useState([]);
  const [currentMonthAbono, setCurrentMonthAbono] = useState(0);
  const [currentMonthProfit, setCurrentMonthProfit] = useState(0); // Añadido aquí
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [isHistoricalModalOpen, setIsHistoricalModalOpen] = useState(false);

  useEffect(() => {
    const getSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          const userData = {
            id: session.user.id,
            name: session.user.user_metadata.name || "Usuario",
            email: session.user.email,
            imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
          };
          setUser(userData);
          setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
          loadData(session.user.id);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error getting session:", error);
      }
    };
  
    getSession();
  
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
        loadData(session.user.id);
      } else {
        setUser(null);
        navigate("/login");
      }
    });
  
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  const calculateMonthlyStats = (ordersData) => {
    const statsByMonth = {};
    let currentMonthTotal = 0;
    let currentMonthProfit = 0;
    const currentDate = new Date();
    const currentMonthStart = startOfMonth(currentDate);
    const currentMonthEnd = endOfMonth(currentDate);

    ordersData.forEach((order) => {
      // Calculate total sales and profit for each product
      const orderDate = new Date(order.fecha);
      const monthKey = format(orderDate, "yyyy-MM");
      
      if (!statsByMonth[monthKey]) {
        statsByMonth[monthKey] = {
          sales: 0,
          profit: 0,
          abonos: 0
        };
      }

      // Add sales and profit
      order.productos.forEach(producto => {
        statsByMonth[monthKey].sales += producto.precio * producto.cantidad;
        statsByMonth[monthKey].profit += (producto.profit || 0) * producto.cantidad;
      });

      // Add abonos
      (order.abonos || []).forEach((abono) => {
        const abonoDate = new Date(abono.fecha);
        const abonoMonthKey = format(abonoDate, "yyyy-MM");
        statsByMonth[abonoMonthKey].abonos += parseFloat(abono.monto);

        if (abonoDate >= currentMonthStart && abonoDate <= currentMonthEnd) {
          currentMonthTotal += parseFloat(abono.monto);
        }
      });

      // Calculate current month stats
      if (orderDate >= currentMonthStart && orderDate <= currentMonthEnd) {
        order.productos.forEach(producto => {
          currentMonthProfit += (producto.profit || 0) * producto.cantidad;
        });
      }
    });

    const sortedMonths = Object.keys(statsByMonth).sort();
    const monthlyStats = sortedMonths.map((month) => ({
      month: format(new Date(month), "MMM yyyy"),
      sales: statsByMonth[month].sales,
      profit: statsByMonth[month].profit,
      abonos: statsByMonth[month].abonos,
    }));

    setMonthlyAbonos(monthlyStats);
    setCurrentMonthAbono(currentMonthTotal);
    setCurrentMonthProfit(currentMonthProfit);
  };

  const loadData = async (userId) => {
    setIsLoading(true);
    try {
      const [clientsData, ordersData, productsData] = await Promise.all([
        fetchClients(userId),
        fetchOrders(userId),
        fetchProducts(),
      ]);
      setClients(clientsData);
      setOrders(ordersData);
      setProducts(productsData);
      calculateMonthlyStats(ordersData);

      const { data: userData, error } = await supabase
        .from('users')
        .select('subscription')
        .eq('id', userId)
        .maybeSingle();

      if (error) throw error;
      setSubscription(userData ? userData.subscription : null);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: true },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Ordenar", href: "/registrar", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(!isAdmin
      ? [{ name: "Suscripción", href: "/subscription-status", current: false }]
      : []),
    ...(isAdmin
      ? [
          { name: "Productos", href: "/productos", current: false },
          { name: "Gestionar Suscripciones", href: "/admin/subscriptions", current: false }
        ]
      : []),
  ];

  const userNavigation = [
    { name: "Tu perfil", to: "/perfil" },
    { name: "Configuraciones", href: "#" },
    { name: "Cerrar sesión", onClick: handleLogout },
  ];

  const calculateMonthlyAbonos = (ordersData) => {
    const abonosByMonth = {};
    let currentMonthTotal = 0;
    const currentDate = new Date();
    const currentMonthStart = startOfMonth(currentDate);
    const currentMonthEnd = endOfMonth(currentDate);

    ordersData.forEach((order) => {
      (order.abonos || []).forEach((abono) => {
        const abonoDate = new Date(abono.fecha);
        const monthKey = format(abonoDate, "yyyy-MM");
        abonosByMonth[monthKey] =
          (abonosByMonth[monthKey] || 0) + parseFloat(abono.monto);

        if (abonoDate >= currentMonthStart && abonoDate <= currentMonthEnd) {
          currentMonthTotal += parseFloat(abono.monto);
        }
      });
    });

    const sortedMonths = Object.keys(abonosByMonth).sort();
    const monthlyAbonosData = sortedMonths.map((month) => ({
      month: format(new Date(month), "MMM yyyy"),
      total: abonosByMonth[month],
    }));

    setMonthlyAbonos(monthlyAbonosData);
    setCurrentMonthAbono(currentMonthTotal);
  };

  const orderStatusData = {
    labels: ['', ''], 
    datasets: [{
      data: [
        orders.filter(order => !order.entregado).length,
        orders.filter(order => order.entregado).length
      ],
      backgroundColor: ['#FF6B6B', '#4CBB17'],
      borderWidth: 0,
    }],
  };

  const monthlyAbonosData = {
    labels: monthlyAbonos.map((item) => item.month),
    datasets: [
      {
        label: "Abonos Mensuales",
        data: monthlyAbonos.map((item) => item.total),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
        borderColor: "rgb(255, 159, 64)",
        borderWidth: 1,
      },
    ],
  };

  const topProducts = useMemo(() => {
    const productSales = products.map((product) => {
      const sales = orders.reduce((total, order) => {
        const productInOrder = order.productos.find((p) => p.id === product.id);
        return total + (productInOrder ? productInOrder.cantidad : 0);
      }, 0);
      return { ...product, sales };
    });

    return productSales.sort((a, b) => b.sales - a.sales).slice(0, 5);
  }, [products, orders]);

  const topClients = useMemo(() => {
    const clientOrders = clients.map((client) => {
      const orderCount = orders.filter(
        (order) => order.clienteId === client.id
      ).length;
      return { ...client, orderCount };
    });

    return clientOrders.sort((a, b) => b.orderCount - a.orderCount).slice(0, 5);
  }, [clients, orders]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  

  return (
    <div className="min-h-full bg-gray-50">
      <Navbar
        user={user}
        navigation={navigation}
        userNavigation={userNavigation}
        handleLogout={handleLogout}
      />

      <main className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-8"
          >
            <h1 className="text-4xl font-bold text-orange-600">
              Dashboard de Ventas
            </h1>
            <p className="mt-2 text-gray-600">Bienvenido/a, {user?.name}</p>
          </motion.div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            <StatCard
              title="Total clientas"
              value={clients.length}
              icon={<UserIcon className="h-10 w-10 text-orange-600" />}
            />
            <StatCard
              title="Total pedidos"
              value={orders.length}
              icon={<ShoppingCartIcon className="h-10 w-10 text-orange-600" />}
            />
            <StatCard
              title="Pedidos pendientes"
              value={orders.filter((order) => !order.entregado).length}
              icon={
                <ClipboardDocumentListIcon className="h-10 w-10 text-orange-600" />
              }
            />
            <StatCard
              title="Total productos"
              value={products.length}
              icon={<CubeIcon className="h-10 w-10 text-orange-600" />}
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-800">
                  Resumen Mensual
                </h2>
                <button
                  onClick={() => setIsHistoricalModalOpen(true)}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-orange-600 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <ChartBarIcon className="h-5 w-5 mr-2" />
                  Ver Historial
                </button>
              </div>

              <div className="space-y-4">
                {monthlyAbonos
                  .slice(-3)
                  .reverse()
                  .map((stats, index) => (
                    <div
                      key={stats.month}
                      className="bg-gray-50 rounded-lg p-4"
                    >
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">
                        {stats.month}
                      </h3>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-sm text-gray-600">
                            Ventas Totales
                          </p>
                          <p className="text-lg font-bold text-gray-900">
                            {new Intl.NumberFormat('es-CO', {
                              style: 'currency',
                              currency: 'COP',
                              minimumFractionDigits: 0,
                            }).format(stats.sales)}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-600">Ganancia</p>
                          <p className="text-lg font-bold text-green-600">
                            {new Intl.NumberFormat('es-CO', {
                              style: 'currency',
                              currency: 'COP',
                              minimumFractionDigits: 0,
                            }).format(stats.profit)}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-600">
                            Abonos Recibidos
                          </p>
                          <p className="text-lg font-bold text-blue-600">
                            {new Intl.NumberFormat('es-CO', {
                              style: 'currency',
                              currency: 'COP',
                              minimumFractionDigits: 0,
                            }).format(stats.abonos)}
                          </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

            <div className="bg-white rounded-xl shadow-lg p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-6">¿Cómo van mis pedidos?</h2>
              
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="bg-red-50 rounded-lg p-4 text-center">
                  <p className="text-4xl font-bold text-red-600 mb-2">
                    {orders.filter(order => !order.entregado).length}
                  </p>
                  <p className="text-lg text-red-700">Me faltan entregar</p>
                </div>
                
                <div className="bg-green-50 rounded-lg p-4 text-center">
                  <p className="text-4xl font-bold text-green-600 mb-2">
                    {orders.filter(order => order.entregado).length}
                  </p>
                  <p className="text-lg text-green-700">Ya entregué</p>
                </div>
              </div>

              <div className="bg-blue-50 rounded-lg p-4 text-center">
                <p className="text-2xl font-bold text-blue-600 mb-2">
                  Total de pedidos: {orders.length}
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
            <RankingTable
              title="Top 5 Productos"
              data={topProducts}
              type="products"
            />
            <RankingTable
              title="Top 5 Clientas"
              data={topClients}
              type="clients"
            />
          </div>
        </div>
      </main>
      <HistoricalDataModal
        isOpen={isHistoricalModalOpen}
        onClose={() => setIsHistoricalModalOpen(false)}
        monthlyData={monthlyAbonos}
      />
      
    </div>
  );
}