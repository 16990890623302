import { supabase } from '../supabaseConfig';

export const fetchClients = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('clients')
      .select('*')
      .eq('user_id', userId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching clients: ", error);
    throw error;
  }
};

export const fetchOrders = async (userId) => {
  try {
    if (!userId) {
      throw new Error("User ID is required to fetch orders");
    }
    const { data, error } = await supabase
      .from('orders')
      .select('*')
      .eq('user_id', userId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching orders: ", error);
    throw error;
  }
};

export const fetchProducts = async () => {
  try {
    const { data, error } = await supabase
      .from('products')
      .select('*');
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching products: ", error);
    throw error;
  }
};

export const addClient = async (userId, clientData) => {
  try {
    // Verificar si el user_id existe en la tabla users
    const { data: user, error: userError } = await supabase
      .from('users')
      .select('id')
      .eq('id', userId)
      .single();

    if (userError) {
      throw new Error(`El user_id ${userId} no existe en la tabla users`);
    }

    const { data, error } = await supabase
      .from('clients')
      .insert([{ ...clientData, user_id: userId }]);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error adding client: ", error);
    throw error;
  }
};

export const updateClient = async (clientId, clientData) => {
  try {
    const { data, error } = await supabase
      .from('clients')
      .update(clientData)
      .eq('id', clientId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating client: ", error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData) => {
  try {
    const { data, error } = await supabase
      .from('orders')
      .update(orderData)
      .eq('id', orderId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating order: ", error);
    throw error;
  }
};

export const addOrder = async (userId, orderData) => {
  try {
    const { data, error } = await supabase
      .from('orders')
      .insert([{ ...orderData, user_id: userId }]);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error adding order: ", error);
    throw error;
  }
};

export const deleteClient = async (clientId) => {
  try {
    const { data, error } = await supabase
      .from('clients')
      .delete()
      .eq('id', clientId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error deleting client: ", error);
    throw error;
  }
};

export const deleteOrders = async (orderIds) => {
  try {
    const { data, error } = await supabase
      .from('orders')
      .delete()
      .in('id', orderIds);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error deleting orders: ", error);
    throw error;
  }
};

export const addProduct = async (id, name, imageUrl) => {
  try {
    // Verificar si el producto ya existe
    const { data: existingProduct, error: fetchError } = await supabase
      .from('products')
      .select('*')
      .eq('id', id)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') {
      throw fetchError;
    }

    if (existingProduct) {
      // Si el producto ya existe, actualizarlo
      const { data, error } = await supabase
        .from('products')
        .update({ name, imageUrl })
        .eq('id', id);
      
      if (error) throw error;
      return data;
    } else {
      // Si el producto no existe, insertarlo
      const { data, error } = await supabase
        .from('products')
        .insert([{ id, name, imageUrl }]);
      
      if (error) throw error;
      return data;
    }
  } catch (error) {
    console.error("Error adding product: ", error);
    throw error;
  }
};

export const updateProduct = async (productId, productData) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .update(productData)
      .eq('id', productId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating product: ", error);
    throw error;
  }
};

export const deleteProduct = async (productId) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .delete()
      .eq('id', productId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error deleting product: ", error);
    throw error;
  }
};