import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import {
  UserCircleIcon,
  KeyIcon,
  TrashIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import Navbar from './dashboard/Navbar';

function Perfil() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState('');
  const [newAvatarUrl, setNewAvatarUrl] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const userData = {
        id: currentUser.id,
        name: currentUser.user_metadata?.name || "Usuario",
        email: currentUser.email,
        imageUrl: currentUser.user_metadata?.avatar_url || "/avatar.svg",
      };
      setUser(userData);
      setNewName(userData.name);
      setNewAvatarUrl(userData.imageUrl);
      setIsAdmin(currentUser.email === 'tamayodaza9@gmail.com');
    } else {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  const handleDeleteAccount = async () => {
    if (window.confirm("¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer.")) {
      const password = prompt("Por favor, ingresa tu contraseña actual para confirmar la eliminación de la cuenta:");
      if (!password) {
        toast.error("Se requiere la contraseña para eliminar la cuenta.");
        return;
      }
      setLoading(true);
      try {
        const { error: authError } = await supabase.auth.signInWithPassword({
          email: user.email,
          password: password,
        });
        if (authError) throw authError;

        const { error: deleteError } = await supabase.auth.admin.deleteUser(user.id);
        if (deleteError) throw deleteError;

        toast.success("Cuenta eliminada con éxito");
        navigate("/login");
      } catch (error) {
        console.error("Error al eliminar la cuenta:", error);
        toast.error("Error al eliminar la cuenta: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSendPasswordResetEmail = async () => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(user.email, {
        redirectTo: 'https://yanbal.vercel.app/reset-password'
      });
      if (error) throw error;
      toast.success("Correo de restablecimiento de contraseña enviado con éxito.");
    } catch (error) {
      console.error("Error al enviar el correo de restablecimiento de contraseña:", error);
      toast.error("Error al enviar el correo de restablecimiento de contraseña: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    setLoading(true);
    try {
      const updates = {
        data: {
          name: newName,
          avatar_url: newAvatarUrl,
        },
      };
      const { error } = await supabase.auth.updateUser(updates);
      if (error) throw error;
      toast.success("Perfil actualizado con éxito");
      setUser({ ...user, name: newName, imageUrl: newAvatarUrl });
    } catch (error) {
      console.error("Error al actualizar el perfil:", error);
      toast.error("Error al actualizar el perfil: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Ordenar", href: "/registrar", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(!isAdmin ? [{ name: "Suscripción", href: "/subscription-status", current: false }] : []),
    ...(isAdmin ? [
      { name: "Productos", href: "/productos", current: false },
      { name: "Gestionar Suscripciones", href: "/admin/subscriptions", current: false }
    ] : []),
  ];

  const userNavigation = [
    { name: 'Tu perfil', to: '/perfil' },
    { name: 'Configuraciones', href: '#' },
    { name: 'Cerrar sesión', onClick: async () => {
      await supabase.auth.signOut();
      navigate("/login");
    }},
  ];

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar 
        user={user}
        navigation={navigation}
        userNavigation={userNavigation}
      />

      <main className="py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white shadow-xl rounded-2xl overflow-hidden"
          >
            {/* Header */}
            <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-orange-500 to-orange-600">
              <h3 className="text-2xl leading-6 font-bold text-white">Perfil de Usuario</h3>
              <p className="mt-1 max-w-2xl text-sm text-orange-100">
                Gestiona tu información personal y configuraciones de cuenta
              </p>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
              {/* Profile Image and Basic Info */}
              <div className="flex flex-col sm:flex-row items-center mb-8 space-y-4 sm:space-y-0 sm:space-x-8">
                <div className="relative group">
                  <img
                    src={user.imageUrl}
                    alt="Profile"
                    className="h-32 w-32 rounded-full object-cover border-4 border-white shadow-lg"
                  />
                  <div className="absolute inset-0 rounded-full bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                    <UserCircleIcon className="h-8 w-8 text-white" />
                  </div>
                </div>
                <div className="text-center sm:text-left">
                  <h2 className="text-3xl font-bold text-gray-900">{user.name}</h2>
                  <p className="text-gray-500">{user.email}</p>
                  {isAdmin && (
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-orange-100 text-orange-800 mt-2">
                      Administrador
                    </span>
                  )}
                </div>
              </div>

              {/* Update Profile Form */}
              <div className="bg-gray-50 rounded-xl p-6 mb-8">
                <h4 className="text-xl font-semibold text-gray-900 mb-4">Actualizar Perfil</h4>
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Nombre</label>
                    <input
                      type="text"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">URL del Avatar</label>
                    <input
                      type="text"
                      value={newAvatarUrl}
                      onChange={(e) => setNewAvatarUrl(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                    />
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleUpdateProfile}
                    disabled={loading}
                    className="inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50"
                  >
                    <ArrowPathIcon className="h-5 w-5 mr-2" />
                    {loading ? "Actualizando..." : "Actualizar Perfil"}
                  </motion.button>
                </div>
              </div>

              {/* Security Section */}
              <div className="bg-gray-50 rounded-xl p-6 mb-8">
                <h4 className="text-xl font-semibold text-gray-900 mb-4">Seguridad</h4>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleSendPasswordResetEmail}
                  disabled={loading}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  <KeyIcon className="h-5 w-5 mr-2" />
                  {loading ? "Enviando..." : "Cambiar Contraseña"}
                </motion.button>
              </div>

              {/* Danger Zone */}
              <div className="bg-red-50 rounded-xl p-6">
                <h4 className="text-xl font-semibold text-red-900 mb-4">Zona de Peligro</h4>
                <p className="text-sm text-red-600 mb-4">
                  Una vez que elimines tu cuenta, no hay vuelta atrás. Por favor, estás seguro.
                </p>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleDeleteAccount}
                  disabled={loading}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                >
                  <TrashIcon className="h-5 w-5 mr-2" />
                  {loading ? "Eliminando..." : "Eliminar Cuenta"}
                </motion.button>
              </div>
            </div>
          </motion.div>
        </div>
      </main>
    </div>
  );
}

export default Perfil;