import React, { useState, useEffect } from 'react';
import { differenceInDays, format } from 'date-fns';
import { motion } from 'framer-motion';
import { supabase } from '../supabaseConfig';
import Navbar from './dashboard/Navbar';
import { useNavigate } from 'react-router-dom';
import PremiumPaywall from './PremiumPaywall';

function SubscriptionStatus() {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [showPaywall, setShowPaywall] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      console.log("Usuario ha cerrado sesión");
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleRenew = () => {
    setShowPaywall(true);
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const user = session ? session.user : null;
        if (user) {
          const { data: userDoc, error } = await supabase
            .from('users')
            .select('subscription')
            .eq('id', user.id)
            .single();
          if (error) throw error;

          if (userDoc) {
            setSubscription(userDoc.subscription || null);
          }
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  const calculateRemainingDays = () => {
    if (!subscription || !subscription.endDate) return 0;
    const endDate = new Date(subscription.endDate);
    const today = new Date();
    return Math.max(0, differenceInDays(endDate, today));
  };

  const getStatusColor = () => {
    const days = calculateRemainingDays();
    if (days > 10) return 'bg-green-100 text-green-800';
    if (days > 5) return 'bg-yellow-100 text-yellow-800';
    return 'bg-red-100 text-red-800';
  };

  const navigation = [
    { name: 'Inicio', href: '/inicio', current: false },
    { name: 'Clientes', href: '/clientes', current: false },
    { name: 'Ordenar', href: '/registrar', current: false },
    { name: 'Pedidos', href: '/pedidos', current: false },
    { name: 'Suscripción', href: '/subscription-status', current: true },
  ];

  const userNavigation = [
    { name: 'Tu perfil', to: '/perfil' },
    { name: 'Configuraciones', to: '#' },
    { name: 'Cerrar sesión', to: '#', onClick: handleLogout },
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!subscription) {
    return (
      <div>
        <Navbar navigation={navigation} userNavigation={userNavigation} />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Estado de Suscripción</h1>
          <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <p className="text-gray-600">No tienes una suscripción activa</p>
            <button
              onClick={handleRenew}
              className="mt-4 bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition-colors duration-200"
            >
              Suscribirse
            </button>
          </div>
        </div>
        {showPaywall && <PremiumPaywall onClose={() => setShowPaywall(false)} />}
      </div>
    );
  }

  if (!subscription.verified) {
    return (
      <div>
        <Navbar navigation={navigation} userNavigation={userNavigation} />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Estado de Suscripción</h1>
          <div className="bg-yellow-50 p-4 rounded-lg mt-4">
            <p className="text-yellow-700">
              Tu solicitud de suscripción está pendiente de aprobación
            </p>
            <p className="text-sm text-yellow-600 mt-2">
              Referencia de pago: {subscription.paymentId}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const remainingDays = calculateRemainingDays();
  const expirationDate = subscription ? format(new Date(subscription.endDate), 'dd/MM/yyyy') : '';

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar
        user={user}
        navigation={navigation}
        userNavigation={userNavigation}
        handleLogout={handleLogout}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">Estado de Suscripción</h1>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white p-6 rounded-xl shadow-lg mt-4"
        >
          <h3 className="text-lg font-semibold text-gray-900 mb-4">
            Estado de Suscripción
          </h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Plan</span>
              <span className="font-medium text-orange-600">Premium</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Estado</span>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor()}`}>
                {remainingDays > 0 ? 'Activo' : 'Expirado'}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Días restantes</span>
              <span className="font-bold text-2xl text-gray-900">
                {remainingDays}
              </span>
            </div>
            <div className="pt-4 border-t border-gray-200">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Fecha de inicio</span>
                <span className="text-gray-900">
                  {new Date(subscription.startDate).toLocaleDateString()}
                </span>
              </div>
              <div className="flex justify-between text-sm mt-2">
                <span className="text-gray-500">Fecha de expiración</span>
                <span className="text-gray-900">
                  {expirationDate}
                </span>
              </div>
            </div>
            {remainingDays <= 5 && (
              <div className="mt-4 p-3 bg-red-50 rounded-lg">
                <p className="text-sm text-red-700">
                  ¡Tu suscripción está por expirar! Renueva ahora para mantener los beneficios premium.
                </p>
                <button
                  onClick={handleRenew}
                  className="mt-2 w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition-colors duration-200"
                >
                  Renovar Suscripción
                </button>
              </div>
            )}
          </div>
        </motion.div>
      </div>
      {showPaywall && <PremiumPaywall onClose={() => setShowPaywall(false)} />}
    </div>
  );
}

export default SubscriptionStatus;