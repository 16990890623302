import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  CloudArrowDownIcon,
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  PencilIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseConfig";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Navbar from './dashboard/Navbar';
import {
  addProduct,
  fetchProducts,
  updateProduct,
  deleteProduct,
} from "../services/supabaseService";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Product() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [id, setId] = useState("");
  const [nombre, setNombre] = useState("");
  const [imagen, setImagen] = useState(null);
  const [productos, setProductos] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [lastSyncDate, setLastSyncDate] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const productsPerPage = 10;

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
      } else {
        navigate("/login");
      }
    };
  
    getSession();
  
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
      } else {
        setUser(null);
        setIsAdmin(false);
        navigate("/login");
      }
    });
  
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    const storedLastSync = localStorage.getItem("lastProductSync");
    if (storedLastSync) {
      setLastSyncDate(new Date(storedLastSync));
    }
  }, []);

  const shouldSync = useCallback(() => {
    if (!lastSyncDate) return true;
    const daysSinceLastSync = Math.floor(
      (new Date() - new Date(lastSyncDate)) / (1000 * 60 * 60 * 24)
    );
    return daysSinceLastSync >= 30;
  }, [lastSyncDate]);

  const syncProducts = useCallback(async () => {
    if (isSyncing) return;

    try {
      setIsSyncing(true);
      toast.info("Iniciando sincronización de productos...");

      const allProducts = [];
      let currentPage = 0;
      let hasMoreResults = true;

      while (hasMoreResults) {
        const response = await axios.get(
          `https://www.yanbal.com/co/corporate/search/results/?text=&q=&page=${currentPage}`
        );

        const results = response.data.results || [];
        if (results.length === 0) {
          hasMoreResults = false;
        } else {
          const productsWithFullUrls = results.map((product) => ({
            ...product,
            images: product.images.map((image) => ({
              ...image,
              url: `https://yanbal.com${image.url}`,
            })),
          }));
          allProducts.push(...productsWithFullUrls);
          currentPage++;
        }
      }

      const existingProducts = await fetchProducts();
      const existingIds = new Set(existingProducts.map((p) => p.id));

      let newProductsCount = 0;
      for (const product of allProducts) {
        if (!existingIds.has(product.code)) {
          await addProduct(product.code, product.name, product.images[0].url);
          newProductsCount++;
        }
      }

      const now = new Date();
      setLastSyncDate(now);
      localStorage.setItem("lastProductSync", now.toISOString());

      toast.success(
        `Sincronización completada. ${newProductsCount} nuevos productos importados.`
      );

      const updatedProducts = await fetchProducts();
      setProductos(updatedProducts || []);
      setFilteredProducts(updatedProducts || []);
    } catch (error) {
      console.error("Error en la sincronización:", error);
      toast.error("Error durante la sincronización de productos");
    } finally {
      setIsSyncing(false);
    }
  }, [isSyncing]);

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Ordenar", href: "/registrar", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(isAdmin
      ? [
          { name: "Productos", href: "/productos", current: true },
          { name: "Gestionar Suscripciones", href: "/admin/subscriptions", current: false }
        ]
      : []),
  ];

  
  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  const handleDelete = useCallback(async (productId) => {
    if (!productId) {
      console.error("ID de producto no válido");
      toast.error("Error al eliminar el producto. ID no válido.");
      return;
    }
  
    if (
      window.confirm("¿Estás seguro de que quieres eliminar este producto?")
    ) {
      try {
        const { error } = await deleteProduct(productId);
        if (!error) {
          toast.success("Producto eliminado correctamente");
          setProductos((prevProductos) =>
            prevProductos.filter((product) => product.id !== productId)
          );
          setFilteredProducts((prevFiltered) =>
            prevFiltered.filter((product) => product.id !== productId)
          );
        } else {
          toast.error("No se pudo eliminar el producto de Supabase.");
        }
      } catch (error) {
        console.error("Error al eliminar el producto:", error);
        toast.error(
          "Error al eliminar el producto. Por favor, intenta de nuevo."
        );
      }
    }
  }, []);
  
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };
  
  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "Enter") {
      e.preventDefault();
      if (searchResults.length > 0) {
        handleImport(searchResults[0]);
      } else {
        toast.info(
          "No hay resultados para importar. Realiza una búsqueda primero."
        );
      }
    }
  };
  
  useEffect(() => {
    const filtered = productos.filter((product) =>
      product.id.toLowerCase().includes(searchId.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchId, productos]);
  
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImagen(e.target.files[0]);
    }
  };
  
  const handleSearch = useCallback(
    debounce(async () => {
      if (!searchTerm.trim()) {
        toast.info("Por favor, ingresa un término de búsqueda.");
        return;
      }
  
      try {
        const response = await axios.get(
          `https://www.yanbal.com/co/corporate/search/results/?text=${searchTerm}&q=${searchTerm}`
        );
  
        const results = response.data.results || [];
        const resultsWithFullUrls = results.map((product) => ({
          ...product,
          images: product.images.map((image) => ({
            ...image,
            url: `https://yanbal.com${image.url}`,
          })),
        }));
  
        setSearchResults(resultsWithFullUrls);
  
        if (resultsWithFullUrls.length === 0) {
          toast.info("No se encontraron resultados para la búsqueda.");
        } else {
          toast.success(
            `Se encontraron ${resultsWithFullUrls.length} productos.`
          );
        }
      } catch (error) {
        console.error("Error al buscar productos:", error);
        toast.error("Error al buscar productos. Por favor, intenta de nuevo.");
      }
    }, 300),
    [searchTerm]
  );
  
  const handleImport = useCallback(
    async (product) => {
      try {
        const newCode = searchTerm;
  
        if (newCode.trim() === "") {
          toast.error("El código del producto no puede estar vacío.");
          return;
        }
  
        const existingProduct = productos.find((p) => p.id === newCode);
        if (existingProduct) {
          toast.error("Ya existe un producto con este código.");
          return;
        }
  
        await addProduct(newCode, product.name, product.images[0].url);
        toast.success("Producto importado correctamente");
  
        const updatedProducts = await fetchProducts();
        setProductos(updatedProducts || []);
        setFilteredProducts(updatedProducts || []);
  
        setSearchTerm("");
        setSearchResults([]);
      } catch (error) {
        console.error("Error al importar el producto:", error);
        toast.error(
          "Error al importar el producto. Por favor, intenta de nuevo."
        );
      }
    },
    [productos, searchTerm]
  );
  const defaultImageUrl = "/catalogo.jpg";
  
  const handleRegister = async (e) => {
    e.preventDefault();
  
    const imageToUpload = imagen || defaultImageUrl;
  
    if (!editingProductId && !imageToUpload) {
      toast.error("Por favor, selecciona una imagen.");
      return;
    }
  
    const existingProduct = productos.find(
      (product) => product.id === id && product.id !== editingProductId
    );
    if (existingProduct) {
      toast.error(
        "Ya existe un producto con este ID. Por favor, usa un ID diferente."
      );
      return;
    }
  
    try {
      if (editingProductId) {
        if (editingProductId !== id) {
          await deleteProduct(editingProductId);
          await addProduct(id, nombre, imageToUpload);
        } else {
          await updateProduct(editingProductId, { id, nombre, imageToUpload });
        }
      } else {
        await addProduct(id, nombre, imageToUpload);
      }
  
      setId("");
      setNombre("");
      setImagen(null);
      setEditingProductId(null);
      const updatedProducts = await fetchProducts();
      setProductos(updatedProducts || []);
      setFilteredProducts(updatedProducts || []);
      toast.success(
        editingProductId
          ? "Producto actualizado correctamente"
          : "Producto registrado correctamente"
      );
    } catch (error) {
      console.error("Error al registrar/actualizar el producto:", error);
      toast.error(
        "Error al procesar el producto. Por favor, intenta de nuevo."
      );
    }
  };
  
  const handleEdit = (product) => {
    setId(product.id);
    setNombre(product.nombre);
    setImagen(product.imagen);
    setEditingProductId(product.id);
  };
  
  const userNavigation = [
    { name: 'Tu perfil', to: '/perfil' },
    { name: 'Configuraciones', href: '#' },
    { name: 'Cerrar sesión', onClick: handleLogout },
  ];

  return (
    <div className="min-h-full">
      <Navbar
        user={user}
        navigation={navigation}
        userNavigation={userNavigation}
        handleLogout={handleLogout}
      />

      <main className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Gestión de Productos
          </h1>
          <div className="flex items-center">
            {lastSyncDate && (
              <p className="text-sm text-gray-500 mr-4">
                Última sincronización:{" "}
                {new Date(lastSyncDate).toLocaleDateString()}
              </p>
            )}
            <button
              onClick={syncProducts}
              disabled={isSyncing}
              className="ml-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <CloudArrowDownIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              {isSyncing ? "Sincronizando..." : "Sincronizar Productos"}
            </button>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Buscar e Importar Productos
          </h2>
          <form onSubmit={handleSearchSubmit} className="flex items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Buscar productos de Yanbal"
              className="flex-grow px-3 py-2 bg-white border border-gray-300 rounded-l-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Buscar
            </button>
          </form>
        </div>

        {searchResults.length > 0 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Resultados de la búsqueda
            </h3>
            <ul className="bg-white shadow overflow-hidden sm:rounded-md">
              {searchResults.map((product) => (
                <li
                  key={product.code}
                  className="border-b border-gray-200 last:border-b-0"
                >
                  <div className="px-4 py-4 sm:px-6 flex items-center justify-between hover:bg-gray-50">
                    <div className="flex items-center">
                      <img
                        src={product.images[0].url}
                        alt={product.name}
                        className="h-16 w-16 rounded-full object-cover mr-4"
                      />
                      <div>
                        <p className="text-sm font-medium text-indigo-600 truncate">
                          {product.code}
                        </p>
                        <p className="text-sm text-gray-500">{product.name}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleImport(product)}
                      className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Importar
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <form
          className="bg-white shadow-md rounded-lg p-6 mb-8"
          onSubmit={handleRegister}
        >
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="id"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                ID del Producto
              </label>
              <input
                type="text"
                id="id"
                value={id}
                onChange={(e) => setId(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label
                htmlFor="nombre"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Nombre del Producto
              </label>
              <input
                type="text"
                id="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              />
            </div>
          </div>

          {!editingProductId && (
            <div className="mt-6">
              <label
                htmlFor="imagen"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Imagen del Producto
              </label>
              <input
                type="file"
                id="imagen"
                onChange={handleImageChange}
                className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0
                file:text-sm file:font-semibold
                file:bg-indigo-50 file:text-indigo-700
                hover:file:bg-indigo-100"
              />
              {!imagen && (
                <img
                  src={defaultImageUrl}
                  alt="Imagen predeterminada"
                  className="mt-2 h-16 w-16 rounded-md object-cover"
                />
              )}
            </div>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              {editingProductId ? "Actualizar Producto" : "Registrar Producto"}
            </button>
          </div>
        </form>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Productos Registrados
        </h2>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Buscar por ID del producto"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
            focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
          />
        </div>

        <ul className="bg-white shadow overflow-hidden sm:rounded-md">
          {currentProducts.map((producto) => (
            <li
              key={producto.id}
              className="border-b border-gray-200 last:border-b-0"
            >
              <div className="px-4 py-4 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center justify-between hover:bg-gray-50">
                <div className="flex items-center mb-2 sm:mb-0">
                  <img
                    src={producto.imagen}
                    alt={producto.nombre}
                    className="h-16 w-16 rounded-full object-cover mr-4"
                  />
                  <div>
                    <p className="text-sm font-medium text-indigo-600 truncate">
                      {producto.id}
                    </p>
                    <p className="text-sm text-gray-500">{producto.nombre}</p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
                  <button
                    onClick={() => handleEdit(producto)}
                    className="w-full sm:w-auto inline-flex justify-center items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                  <button
                    onClick={() => handleDelete(producto.id)}
                    className="w-full sm:w-auto inline-flex justify-center items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {filteredProducts.length > productsPerPage && (
          <div className="mt-4 flex justify-center">
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                Anterior
              </button>
              {[
                ...Array(Math.ceil(filteredProducts.length / productsPerPage)),
              ].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={classNames(
                    currentPage === index + 1
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo -500"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50",
                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  )}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredProducts.length / productsPerPage)
                }
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                Siguiente
              </button>
            </nav>
          </div>
        )}
      </main>
    </div>
  );
}
