import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import {
  QrCodeIcon,
  PhoneIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { supabase } from '../supabaseConfig';
import { PaymentStep, PaymentReference } from './PaymentSteps';
import StatusDisplay from './SubscriptionStatusPay';
import { useSubscription } from '../hooks/useSubscription';
import './PremiumPaywall.css';

const NEQUI_NUMBER = '3112900929';
const QR_CODE_PATH = '/qr_nequi.png';

export default function PremiumPaywall({ onClose }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const {
    isProcessing,
    subscriptionStatus,
    paymentReference,
    setPaymentReference,
    counter,
    expandedStep,
    setExpandedStep,
    handlePayment,
  } = useSubscription({ onClose });

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      toast.success('Sesión cerrada exitosamente.');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const handleRetry = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const user = session.user;
        await supabase
          .from('users')
          .update({
            subscription: {
              status: 'pagando',
              paymentId: null,
            },
          })
          .eq('id', user.id);
        setPaymentReference('');
        setShowModal(true); // Show the modal
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      toast.error('Error al procesar la suscripción');
    }
  };

  const handleCopyNumber = async () => {
    try {
      await navigator.clipboard.writeText(NEQUI_NUMBER);
      toast.success('Número copiado al portapapeles');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Error al copiar el número');
    }
  };

  if (showModal || ['activo', 'pendiente', 'cancelado', 'rechazado'].includes(subscriptionStatus)) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <StatusDisplay
            status={subscriptionStatus}
            counter={counter}
            NEQUI_NUMBER={NEQUI_NUMBER}
            handleRetry={handleRetry}
            handleLogout={handleLogout}
            onClose={onClose}
          />
        </motion.div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Desbloquea Contenido Premium
        </h2>

        <div className="space-y-2 mb-6">
          <PaymentStep
            step={1}
            title="Escanea el código QR"
            icon={QrCodeIcon}
            expandedStep={expandedStep}
            setExpandedStep={setExpandedStep}
          >
            <img
              src={QR_CODE_PATH}
              alt="Código QR Nequi"
              className="mx-auto w-48 h-48"
            />
          </PaymentStep>

          <PaymentStep
            step={2}
            title="O envía el pago por Nequi"
            icon={PhoneIcon}
            expandedStep={expandedStep}
            setExpandedStep={setExpandedStep}
          >
            <div className="text-center">
              <p className="font-mono text-lg text-orange-600 font-bold">
                {NEQUI_NUMBER}
              </p>
              <p className="text-sm text-gray-600 mt-2">
                Envía $10.000 COP a este número
              </p>
              <button
                onClick={handleCopyNumber}
                className="mt-2 inline-flex items-center bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-200"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 12h.01M12 12h.01M16 12h.01M9 16h6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                Copiar Número
              </button>
            </div>
          </PaymentStep>

          <PaymentStep
            step={3}
            title="Ingresa la referencia del pago"
            icon={DocumentTextIcon}
            expandedStep={expandedStep}
            setExpandedStep={setExpandedStep}
          >
            <PaymentReference
              paymentReference={paymentReference}
              setPaymentReference={setPaymentReference}
              handlePayment={handlePayment}
              isProcessing={isProcessing}
            />
          </PaymentStep>
        </div>

        <button
          onClick={handleLogout}
          className="w-full bg-gray-100 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200 transition-colors duration-200"
        >
          Cerrar Sesión
        </button>
      </motion.div>
    </motion.div>
  );
}