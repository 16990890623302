import React from 'react';
import ProtectedFeature from '../ProtectedFeature';

// Higher Order Component for protecting entire pages
export default function withPremiumProtection(WrappedComponent, options = {}) {
  const { excludeFromProtection = false } = options;
  
  return function WithPremiumProtectionWrapper(props) {
    if (excludeFromProtection) {
      return <WrappedComponent {...props} />;
    }

    return (
      <ProtectedFeature>
        <WrappedComponent {...props} />
      </ProtectedFeature>
    );
  };
}