import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export function PaymentStep({ step, title, icon: Icon, children, expandedStep, setExpandedStep }) {
  return (
    <div className="border rounded-lg mb-2">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setExpandedStep(expandedStep === step ? null : step);
        }}
        className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50 rounded-lg focus:outline-none"
      >
        <div className="flex items-center space-x-3">
          <Icon className="h-5 w-5 text-orange-500" />
          <span className="font-medium text-gray-900">{title}</span>
        </div>
        <ChevronDownIcon
          className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
            expandedStep === step ? 'rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence>
        {expandedStep === step && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="px-4 py-3 border-t" onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export function PaymentReference({ paymentReference, setPaymentReference, handlePayment, isProcessing }) {
  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setPaymentReference(text);
      toast.success('Referencia pegada exitosamente');
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
      toast.error('Error al pegar la referencia');
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type="text"
          value={paymentReference}
          onChange={(e) => setPaymentReference(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
          placeholder="Referencia del pago"
        />
        <button
          onClick={handlePaste}
          className="absolute inset-y-0 right-0 px-3 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 transition-colors duration-200"
        >
          Pegar
        </button>
      </div>
      <button
        onClick={handlePayment}
        disabled={isProcessing}
        className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition-colors duration-200 disabled:opacity-50"
      >
        {isProcessing ? 'Procesando...' : 'Enviar Comprobante'}
      </button>
    </div>
  );
}