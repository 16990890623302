import React, { useState, useEffect } from "react";
import {
  fetchOrders,
  updateOrder,
  deleteOrders,
} from "../services/supabaseService";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import Navbar from './dashboard/Navbar';
import {
  Bars3Icon,
  XMarkIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  ChevronDownIcon,
  DocumentArrowDownIcon,
  ChevronUpIcon,
  CalendarIcon,
  FilterIcon,
  DocumentDownloadIcon,
  TrashIcon,
  CreditCardIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Pedidos.css";
import { utils as xlsxUtils, writeFile } from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PlusIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const parseCOP = (value) => {
  return parseInt(value.replace(/\D/g, ""), 10);
};

function Pedidos() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [historySearchTerm, setHistorySearchTerm] = useState("");
  const [historyFilter, setHistoryFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [expandedOrders, setExpandedOrders] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [abonoMonto, setAbonoMonto] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ordersPerPage = 5;

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
      } else {
        navigate("/login");
      }
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
      } else {
        setUser(null);
        setIsAdmin(false);
        navigate("/login");
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Ordenar", href: "/registrar", current: false },
    { name: "Pedidos", href: "/pedidos", current: true },
    ...(!isAdmin
      ? [{ name: "Suscripción", href: "/subscription-status", current: false }]
      : []),
    ...(isAdmin
      ? [
        { name: "Productos", href: "/productos", current: false },
        { name: "Gestionar Suscripciones", href: "/admin/subscriptions", current: false }
      ]
      : []),
  ];

  const formatNumber = (value) => {
    const number = value.replace(/\D/g, "");
    return new Intl.NumberFormat("es-CO").format(number);
  };

  const formatToCOP = (number) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(number)
      .replace("COP", "")
      .trim();
  };

  const parseCOP = (value) => {
    return parseInt(value.replace(/\D/g, ""), 10);
  };

  const handleAbonoChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    if (value === "") {
      setAbonoMonto("");
    } else {
      const numberValue = parseInt(value, 10);
      setAbonoMonto(formatToCOP(numberValue));
    }
  };

  const handleCancel = async (orderId) => {
    try {
      const order = orders.find((o) => o.id === orderId);
      if (order) {
        await updateOrder(orderId, {
          ...order,
          cancelado: true,
          fechaCancelacion: new Date().toISOString(),
        });
        await loadOrders();
        toast.success("Pedido cancelado exitosamente");
      }
    } catch (error) {
      console.error("Error al cancelar el pedido:", error);
      toast.error("Error al cancelar el pedido");
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const userNavigation = [
    { name: 'Tu perfil', to: '/perfil' },
    { name: 'Configuraciones', href: '#' },
    { name: 'Cerrar sesión', onClick: handleLogout },
  ];

  useEffect(() => {
    if (user) {
      loadOrders();
    }
  }, [user]);

  const loadOrders = async () => {
    setIsLoading(true);
    try {
      if (user) {
        const ordersData = await fetchOrders(user.id);
        console.log("Pedidos cargados:", ordersData); // Para depuración
        const currentOrders = ordersData.filter((order) => !order.entregado);
        const historyOrders = ordersData.filter((order) => order.entregado);
        setOrders(currentOrders);
        setOrderHistory(historyOrders);
      }
    } catch (error) {
      console.error("Error cargando pedidos:", error);
      toast.error("Error al cargar los pedidos");
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportToExcel = () => {
    const ordersToExport =
      selectedOrders.length > 0
        ? filteredHistoryOrders.filter((order) =>
          selectedOrders.includes(order.id)
        )
        : filteredHistoryOrders;

    // Crear una hoja de cálculo
    const worksheet = xlsxUtils.json_to_sheet([]);

    // Definir estilos
    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" } },
      fill: { type: "pattern", pattern: "solid", fgColor: { rgb: "4B0082" } },
      alignment: { horizontal: "center", vertical: "center" },
      border: {
        top: { style: "medium" },
        bottom: { style: "medium" },
        left: { style: "medium" },
        right: { style: "medium" },
      },
    };
    const currencyStyle = {
      numFmt: '"$"#,##0',
      font: { color: { rgb: "000000" } },
      fill: { type: "pattern", pattern: "solid", fgColor: { rgb: "FFF0F5" } },
      alignment: { horizontal: "right" },
    };
    const dateStyle = {
      numFmt: "dd/mm/yyyy",
      font: { color: { rgb: "000000" } },
      fill: { type: "pattern", pattern: "solid", fgColor: { rgb: "E6E6FA" } },
      alignment: { horizontal: "center" },
    };
    const textStyle = {
      alignment: { wrapText: true, vertical: "top" },
      fill: { type: "pattern", pattern: "solid", fgColor: { rgb: "F0F8FF" } },
    };

    // Añadir título y fecha de generación
    xlsxUtils.sheet_add_aoa(worksheet, [["Historial de Pedidos"]], {
      origin: "A1",
    });
    worksheet["A1"].s = {
      font: { bold: true, size: 16 },
      alignment: { horizontal: "center" },
    };
    xlsxUtils.sheet_add_aoa(
      worksheet,
      [[`Generado el: ${new Date().toLocaleString()}`]],
      { origin: "A2" }
    );
    worksheet["A2"].s = {
      font: { italic: true },
      alignment: { horizontal: "center" },
    };

    // Ajustar el índice de inicio de los datos
    const dataStartRow = 4;

    // Añadir encabezados con estilo
    xlsxUtils.sheet_add_aoa(
      worksheet,
      [
        [
          "ID del Pedido",
          "Nombre del Cliente",
          "Fecha de Pedido",
          "Fecha de Entrega",
          "Total del Pedido",
          "Total Abonado",
          "Saldo Pendiente",
          "Estado",
          "Productos",
          "Historial de Abonos",
        ],
      ],
      { origin: `A${dataStartRow}` }
    );

    // Aplicar estilo a los encabezados
    [
      `A${dataStartRow}`,
      `B${dataStartRow}`,
      `C${dataStartRow}`,
      `D${dataStartRow}`,
      `E${dataStartRow}`,
      `F${dataStartRow}`,
      `G${dataStartRow}`,
      `H${dataStartRow}`,
      `I${dataStartRow}`,
      `J${dataStartRow}`,
    ].forEach((cell) => {
      worksheet[cell].s = headerStyle;
    });

    // Añadir datos
    ordersToExport.forEach((order, index) => {
      const rowIndex = index + dataStartRow + 1;
      xlsxUtils.sheet_add_aoa(
        worksheet,
        [
          [
            order.id,
            order.clienteNombre,
            new Date(order.fecha),
            order.entregado ? new Date(order.fechaEntrega) : "No entregado",
            calculateTotal(order.productos),
            order.totalAbonado || 0,
            calculateTotal(order.productos) - (order.totalAbonado || 0),
            order.entregado ? "Entregado" : "Pendiente",
            order.productos
              .map((p) => `${p.name} (${p.cantidad})`)
              .join("\n"),
            (order.abonos || [])
              .map((a) => `${formatDate(a.fecha)}: $${a.monto}`)
              .join("\n"),
          ],
        ],
        { origin: `A${rowIndex}` }
      );

      // Aplicar estilos
      [`A${rowIndex}`, `B${rowIndex}`].forEach((cell) => {
        worksheet[cell].s = textStyle;
      });
      [`C${rowIndex}`, `D${rowIndex}`].forEach((cell) => {
        worksheet[cell].s = dateStyle;
      });
      [`E${rowIndex}`, `F${rowIndex}`, `G${rowIndex}`].forEach((cell) => {
        worksheet[cell].s = currencyStyle;
      });
      worksheet[`H${rowIndex}`].s = {
        font: {
          bold: true,
          color: { rgb: order.entregado ? "008000" : "FF0000" },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: order.entregado ? "E6FFE6" : "FFE6E6" },
        },
        alignment: { horizontal: "center" },
      };
      [`I${rowIndex}`, `J${rowIndex}`].forEach((cell) => {
        worksheet[cell].s = textStyle;
      });
    });

    // Ajustar el ancho de las columnas
    const columnsWidth = [
      { wch: 15 }, // ID del Pedido
      { wch: 30 }, // Nombre del Cliente
      { wch: 15 }, // Fecha de Pedido
      { wch: 15 }, // Fecha de Entrega
      { wch: 18 }, // Total del Pedido
      { wch: 18 }, // Total Abonado
      { wch: 18 }, // Saldo Pendiente
      { wch: 12 }, // Estado
      { wch: 50 }, // Productos
      { wch: 30 }, // Historial de Abonos
    ];
    worksheet["!cols"] = columnsWidth;










    // Agregar bordes a todas las celdas
    const range = xlsxUtils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = xlsxUtils.encode_cell({ r: R, c: C });
        if (!worksheet[cell_address]) continue;
        worksheet[cell_address].s = worksheet[cell_address].s || {};
        worksheet[cell_address].s.border = {
          top: { style: "thin", color: { auto: 1 } },
          bottom: { style: "thin", color: { auto: 1 } },
          left: { style: "thin", color: { auto: 1 } },
          right: { style: "thin", color: { auto: 1 } },
        };
      }
    }

    // Añadir pie de página
    const footerRow = range.e.r + 2;
    xlsxUtils.sheet_add_aoa(
      worksheet,
      [[`Total de pedidos: ${ordersToExport.length}`]],
      { origin: `A${footerRow}` }
    );
    worksheet[`A${footerRow}`].s = { font: { bold: true } };

    // Congelar la primera fila
    worksheet["!freeze"] = {
      xSplit: "1",
      ySplit: dataStartRow.toString(),
      topLeftCell: "B" + (dataStartRow + 1).toString(),
      activePane: "bottomRight",
    };

    // Crear el libro y añadir la hoja
    const workbook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(workbook, worksheet, "Pedidos");

    // Guardar el archivo
    writeFile(workbook, "Historial_Pedidos.xlsx", {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    });
  };

  const handleDelivered = async (orderId) => {
    try {
      const order = orders.find((o) => o.id === orderId);
      if (order) {
        const fechaEntrega = new Date().toISOString();
        await updateOrder(orderId, { ...order, entregado: true, fechaEntrega });
        await loadOrders();
        toast.success("Pedido marcado como entregado");
      }
    } catch (error) {
      console.error("Error al marcar como entregado:", error);
      toast.error("Error al marcar el pedido como entregado");
    }
  };

  const handleSelectAllOrders = () => {
    if (selectedOrders.length === filteredHistoryOrders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(filteredHistoryOrders.map((order) => order.id));
    }
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handlePayTotal = async (orderId) => {
    try {
      const order =
        orders.find((o) => o.id === orderId) ||
        orderHistory.find((o) => o.id === orderId);
      if (order) {
        const saldoPendiente =
          calculateTotal(order.productos) - (order.totalAbonado || 0);
        if (saldoPendiente <= 0) {
          toast.info("Este pedido ya está completamente pagado.");
          return;
        }

        const nuevoAbono = {
          fecha: new Date().toISOString(),
          monto: saldoPendiente,
        };
        const nuevosAbonos = [...(order.abonos || []), nuevoAbono];
        const totalAbonado = calculateTotal(order.productos);

        await updateOrder(orderId, {
          ...order,
          abonos: nuevosAbonos,
          totalAbonado,
        });
        await loadOrders();
        toast.success("Pago total realizado exitosamente");
      }
    } catch (error) {
      console.error("Error al realizar el pago total:", error);
      toast.error("Error al realizar el pago total");
    }
  };

  const handleDeleteSelectedOrders = async () => {
    if (
      window.confirm(
        "¿Estás seguro de que quieres borrar los pedidos seleccionados?"
      )
    ) {
      try {
        setIsLoading(true);
        await deleteOrders(selectedOrders);
        await loadOrders();
        setSelectedOrders([]);
        toast.success("Pedidos borrados exitosamente");
      } catch (error) {
        console.error("Error al borrar pedidos:", error);
        toast.error("Error al borrar los pedidos");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddAbono = async (orderId, monto) => {
    try {
      const order =
        orders.find((o) => o.id === orderId) ||
        orderHistory.find((o) => o.id === orderId);
      if (order) {
        const saldoPendiente =
          calculateTotal(order.productos) - (order.totalAbonado || 0);

        if (saldoPendiente <= 0) {
          toast.info(
            "No se pueden agregar más abonos. El saldo está completamente pagado."
          );
          return;
        }

        const montoAbono = parseCOP(monto);

        if (!montoAbono || montoAbono <= 0) {
          toast.error("El monto del abono debe ser mayor que cero");
          return;
        }

        if (montoAbono > saldoPendiente) {
          toast.error("El monto del abono no puede superar el saldo pendiente");
          return;
        }

        const nuevoAbono = {
          fecha: new Date().toISOString(),
          monto: montoAbono,
        };
















        const nuevosAbonos = [...(order.abonos || []), nuevoAbono];
        const totalAbonado = nuevosAbonos.reduce(
          (total, abono) =>
            total +
            (typeof abono.monto === "number"
              ? abono.monto
              : parseCOP(abono.monto)),
          0
        );
        await updateOrder(orderId, {
          ...order,
          abonos: nuevosAbonos,
          totalAbonado,
        });
        await loadOrders();
        setAbonoMonto("");
        toast.success("Abono agregado exitosamente");
      }
    } catch (error) {
      console.error("Error al agregar abono:", error);
      toast.error("Error al agregar el abono");
    }
  };

  const calculateTotal = (productos) => {
    return productos.reduce(
      (acc, p) => acc + parseFloat(p.precio) * parseFloat(p.cantidad),
      0
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrders((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const filteredOrders = orders.filter(
    (order) =>
      order.clienteNombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.productos.some((producto) =>
        producto.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const filteredHistoryOrders = orderHistory
    .filter((order) => {
      const orderDate = new Date(order.fecha);
      const matchesSearch =
        order.clienteNombre
          .toLowerCase()
          .includes(historySearchTerm.toLowerCase()) ||
        order.productos.some((producto) =>
          producto.name
            .toLowerCase()
            .includes(historySearchTerm.toLowerCase())
        );
      const matchesDateRange =
        (!startDate || orderDate >= startDate) &&
        (!endDate || orderDate <= endDate);
      const matchesFilter =
        historyFilter === "all" ||
        (historyFilter === "delivered" && order.entregado) ||
        (historyFilter === "pending" && !order.entregado && !order.cancelado) ||
        (historyFilter === "canceled" && order.cancelado);

      return matchesSearch && matchesDateRange && matchesFilter;
    })
    .sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const calculateTotalProfit = (productos) => {
    return productos.reduce(
      (acc, p) => acc + (parseFloat(p.profit) || 0) * parseFloat(p.cantidad),
      0
    );
  };


  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar
        user={user}
        navigation={navigation}
        userNavigation={userNavigation}
        handleLogout={handleLogout}
      />
  
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-8">
          <h1 className="text-4xl font-light text-gray-900 tracking-tight">
            Gestión de <span className="font-medium text-rose-600">Pedidos</span>
          </h1>
          <p className="mt-2 text-lg text-gray-600">
            Administra tus pedidos y realiza un seguimiento detallado de cada uno
          </p>
        </div>
  
        {/* Search and Filter Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="relative">
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Buscar por cliente o producto..."
                className="pl-10 w-full rounded-lg border-gray-200 focus:ring-rose-500 focus:border-rose-500"
              />
            </div>
  
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Fecha inicial"
                className="w-full rounded-lg border-gray-200"
              />
              <CalendarIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
  
            <div className="relative">
              <select
                value={historyFilter}
                onChange={(e) => setHistoryFilter(e.target.value)}
                className="w-full rounded-lg border-gray-200 focus:ring-rose-500 focus:border-rose-500 pl-10"
              >
                <option value="all">Todos los pedidos</option>
                <option value="delivered">Entregados</option>
                <option value="pending">Pendientes</option>
                <option value="canceled">Cancelados</option>
              </select>
              <FunnelIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
  
        {/* Active Orders Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-medium text-gray-900 mb-6">
            Pedidos Activos
          </h2>
  
          <div className="space-y-4">
            {currentOrders.map((order) => (
              <motion.div
                key={order.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden hover:shadow-md transition-shadow duration-200"
              >
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        {order.clienteNombre}
                      </h3>
                      <p className="text-sm text-gray-500">
                        Pedido realizado el {formatDate(order.fecha)}
                      </p>
                    </div>
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-rose-100 text-rose-800">
                      Pendiente
                    </span>
                  </div>
  
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm font-medium text-gray-500">Total</p>
                      <p className="text-xl font-semibold text-gray-900">
                        {formatToCOP(calculateTotal(order.productos))}
                      </p>
                    </div>
  
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm font-medium text-gray-500">Ganancia</p>
                      <p className="text-xl font-semibold text-green-600">
                        {formatToCOP(calculateTotalProfit(order.productos))}
                      </p>
                    </div>
  
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm font-medium text-gray-500">Productos</p>
                      <p className="text-xl font-semibold text-gray-900">
                        {order.productos.length}
                      </p>
                    </div>
                  </div>
  
                  <div className="flex flex-wrap gap-4">
                    <button
                      onClick={() => handleDelivered(order.id)}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      <CheckCircleIcon className="h-5 w-5 mr-2" />
                      Marcar como entregado
                    </button>
  
                    <button
                      onClick={() => handleCancel(order.id)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      <ExclamationCircleIcon className="h-5 w-5 mr-2" />
                      Cancelar pedido
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>
  
        {/* Order History Section */}
        <section>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-medium text-gray-900">
              Historial de Pedidos
            </h2>
  
            <div className="flex gap-4">
              <button
                onClick={handleExportToExcel}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
                Exportar a Excel
              </button>
  
              <button
                onClick={handleDeleteSelectedOrders}
                disabled={selectedOrders.length === 0}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
              >
                <TrashIcon className="h-5 w-5 mr-2" />
                Eliminar seleccionados
              </button>
            </div>
          </div>
  
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-rose-500"></div>
            </div>
          ) : filteredHistoryOrders.length > 0 ? (
            <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-4 border-b border-gray-100">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedOrders.length === filteredHistoryOrders.length}
                    onChange={handleSelectAllOrders}
                    className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded"
                  />
                  <label className="ml-2 text-sm text-gray-900">
                    Seleccionar todos
                  </label>
                </div>
              </div>
  
              <ul className="divide-y divide-gray-100">
                {filteredHistoryOrders.map((order) => (
                  <Disclosure as="li" key={order.id}>
                    {({ open }) => (
                      <>
                        <div className="p-4">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              checked={selectedOrders.includes(order.id)}
                              onChange={() => handleSelectOrder(order.id)}
                              className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded mr-4"
                            />
                            <Disclosure.Button className="flex-grow flex items-center justify-between">
                              <div className="flex items-center gap-4">
                                <div>
                                  <p className="text-sm font-medium text-gray-900">
                                    {order.clienteNombre}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {formatDate(order.fecha)}
                                  </p>
                                </div>
                                <span
                                  className={`px-2 py-1 text-xs font-medium rounded-full ${
                                    order.entregado
                                      ? "bg-green-100 text-green-800"
                                      : order.cancelado
                                      ? "bg-red-100 text-red-800"
                                      : "bg-yellow-100 text-yellow-800"
                                  }`}
                                >
                                  {order.cancelado
                                    ? "Cancelado"
                                    : order.entregado
                                    ? "Entregado"
                                    : "Pendiente"}
                                </span>
                              </div>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-gray-500`}
                              />
                            </Disclosure.Button>
                          </div>
                        </div>
  
                        <Disclosure.Panel className="p-4 bg-gray-50">
                          {/* Order Details Panel */}
                          <div className="space-y-6">
                            {/* Products List */}
                            <div>
                              <h4 className="text-sm font-medium text-gray-900 mb-3">
                                Productos
                              </h4>
                              <ul className="divide-y divide-gray-200 bg-white rounded-lg overflow-hidden">
                                {order.productos.map((producto, idx) => (
                                  <li
                                    key={idx}
                                    className="flex items-center justify-between p-4"
                                  >
                                    <div className="flex items-center">
                                      <img
                                        src={producto.imagen}
                                        alt={producto.nombre}
                                        className="h-12 w-12 rounded-lg object-cover"
                                      />
                                      <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                          {producto.nombre}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          Cantidad: {producto.cantidad}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="text-right">
                                      <p className="text-sm font-medium text-gray-900">
                                        {formatToCOP(
                                          producto.precio * producto.cantidad
                                        )}
                                      </p>
                                      <p className="text-xs text-green-600">
                                        Ganancia:{" "}
                                        {formatToCOP(
                                          producto.profit * producto.cantidad
                                        )}
                                      </p>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
  
                            {/* Payment Summary */}
                            <div className="bg-white rounded-lg p-4">
                              <h4 className="text-sm font-medium text-gray-900 mb-4">
                                Resumen de Pagos
                              </h4>
                              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div className="bg-gray-50 rounded-lg p-3">
                                  <p className="text-xs text-gray-500">
                                    Total Pedido
                                  </p>
                                  <p className="text-lg font-medium text-gray-900">
                                    {formatToCOP(calculateTotal(order.productos))}
                                  </p>
                                </div>
                                <div className="bg-gray-50 rounded-lg p-3">
                                  <p className="text-xs text-gray-500">
                                    Total Abonado
                                  </p>
                                  <p className="text-lg font-medium text-green-600">
                                    {formatToCOP(order.totalAbonado || 0)}
                                  </p>
                                </div>
                                <div className="bg-gray-50 rounded-lg p-3">
                                  <p className="text-xs text-gray-500">
                                    Saldo Pendiente
                                  </p>
                                  <p className="text-lg font-medium text-rose-600">
                                    {formatToCOP(
                                      calculateTotal(order.productos) -
                                        (order.totalAbonado || 0)
                                    )}
                                  </p>
                                </div>
                              </div>
  
                              {/* Add Payment Section */}
                              {calculateTotal(order.productos) -
                                (order.totalAbonado || 0) >
                              0 ? (
                                <div className="mt-4">
                                  <div className="flex gap-4">
                                    <div className="flex-grow">
                                      <label className="sr-only">
                                        Monto del abono
                                      </label>
                                      <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                          <span className="text-gray-500">$</span>
                                        </div>
                                        <input
                                          type="text"
                                          value={abonoMonto}
                                          onChange={handleAbonoChange}
                                          className="block w-full pl-7 pr-12 py-2 border-gray-300 rounded-lg focus:ring-rose-500 focus:border-rose-500"
                                          placeholder="Ingrese el monto"
                                        />
                                      </div>
                                    </div>
                                    <button
                                      onClick={() =>
                                        handleAddAbono(order.id, abonoMonto)
                                      }
                                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-rose-600 hover:bg-rose-700"
                                    >
                                      <PlusIcon className="h-5 w-5 mr-2" />
                                      Agregar Abono
                                    </button>
                                    <button
                                      onClick={() => handlePayTotal(order.id)}
                                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-green-600 hover:bg-green-700"
                                    >
                                      Pago Total
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="mt-4 bg-green-50 border-l-4 border-green-400 p-4 rounded-lg">
                                  <p className="text-sm text-green-700">
                                    Pedido completamente pagado
                                  </p>
                                </div>
                              )}
  
                              {/* Payment History */}
                              {order.abonos && order.abonos.length > 0 && (
                                <div className="mt-6">
                                  <h5 className="text-sm font-medium text-gray-900 mb-3">
                                    Historial de Abonos
                                  </h5>
                                  <ul className="divide-y divide-gray-200 bg-gray-50 rounded-lg">
                                    {order.abonos.map((abono, idx) => (
                                      <li
                                        key={idx}
                                        className="flex justify-between p-3"
                                      >
                                        <div>
                                          <p className="text-sm text-gray-900">
                                            Abono #{idx + 1}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            {formatDate(abono.fecha)}
                                          </p>
                                        </div>
                                        <p className="text-sm font-medium text-gray-900">
                                          {formatToCOP(abono.monto)}
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </ul>
            </div>
          ) : (
            <div className="text-center py-12 bg-white rounded-xl shadow-sm border border-gray-100">
              <p className="text-gray-500">No se encontraron pedidos en el historial.</p>
            </div>
          )}
        </section>
      </main>
      <ToastContainer position="top-right" />
    </div>
  );

}

export default Pedidos;
