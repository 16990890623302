import { useState, useEffect } from 'react';
import { supabase } from '../supabaseConfig';

export function usePremiumCheck() {
  const [hasPremium, setHasPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    let subscriptionListener;

    const setupRealtimeSubscription = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const user = session?.user;

        if (!user) {
          setHasPremium(false);
          setLoading(false);
          return;
        }

        setIsAdmin(user.email === 'tamayodaza9@gmail.com');

        if (user.email === 'tamayodaza9@gmail.com') {
          setLoading(false);
          return;
        }

        const checkPremiumStatus = async () => {
          try {
            const { data: userDoc, error: fetchError } = await supabase
              .from('users')
              .select('subscription')
              .eq('id', user.id)
              .single();

            if (fetchError) throw fetchError;

            if (userDoc?.subscription) {
              const { verified, endDate } = userDoc.subscription;
              const isActive = verified && endDate && new Date(endDate) > new Date();
              setHasPremium(isActive);
            } else {
              setHasPremium(false);
            }
          } catch (error) {
            console.error('Error checking premium status:', error);
            setError(error);
            setHasPremium(false);
          } finally {
            setLoading(false);
          }
        };

        // Initial check
        await checkPremiumStatus();

        // Set up real-time listener
        subscriptionListener = supabase
          .channel('user-subscription')
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'users',
              filter: `id=eq.${user.id}`,
            },
            async (payload) => {
              const subscription = payload.new?.subscription;
              if (subscription) {
                const isActive = subscription.verified && 
                               subscription.endDate && 
                               new Date(subscription.endDate) > new Date();
                setHasPremium(isActive);
              } else {
                setHasPremium(false);
              }
            }
          )
          .subscribe();
      } catch (error) {
        console.error('Error setting up real-time subscription:', error);
        setError(error);
        setLoading(false);
      }
    };

    setupRealtimeSubscription();

    return () => {
      if (subscriptionListener) {
        subscriptionListener.unsubscribe();
      }
    };
  }, []);

  return { hasPremium, loading, error, isAdmin };
}