import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import LoginForm from "./components/LoginForm";
import ClientForm from "./components/ClientForm";
import ProductSearch from "./components/ProductSearch";
import OrderForm from "./components/OrderForm";
import Pedidos from "./components/Pedidos";
import Perfil from "./components/Perfil";
import Home from "./components/Home";
import Landing from "./components/Landing";
import AdminSubscriptions from "./components/AdminSubscriptions";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import withPremiumProtection from "./components/HOC/withPremiumProtection";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { supabase } from './supabaseConfig';
import SubscriptionStatus from './components/SubscriptionStatus';
import { AuthProvider } from './context/AuthContext'; // Importa el AuthProvider

// Apply premium protection to components
const ProtectedHome = withPremiumProtection(Home);
const ProtectedClientForm = withPremiumProtection(ClientForm);
const ProtectedProductSearch = withPremiumProtection(ProductSearch);
const ProtectedOrderForm = withPremiumProtection(OrderForm);
const ProtectedPedidos = withPremiumProtection(Pedidos);
const ProtectedPerfil = withPremiumProtection(Perfil);

// Exclude Landing page from protection
const PublicLanding = withPremiumProtection(Landing, { excludeFromProtection: true });

function App() {
  return (
    <AuthProvider> {/* Envuelve tu aplicación con AuthProvider */}
      <Router>
        <Routes>
          <Route path="/" element={
            <AuthWrapper>
              {(user) => user ? <Navigate to="/inicio" replace /> : <PublicLanding />}
            </AuthWrapper>
          } />

          <Route path="/register" element={<RegisterForm />} />
          <Route path="/login" element={<LoginForm />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/inicio" element={<ProtectedHome />} />
            <Route path="/perfil" element={<ProtectedPerfil />} />
            <Route path="/clientes" element={<ProtectedClientForm />} />
            <Route path="/registrar" element={<ProtectedOrderForm />} />
            <Route path="/pedidos" element={<ProtectedPedidos />} />
            <Route path="/productos" element={<ProtectedProductSearch />} />
            <Route path="/subscription-status" element={<SubscriptionStatus />} />
          </Route>

          <Route element={<AdminRoute />}>
            <Route path="/admin/subscriptions" element={<AdminSubscriptions />} />
          </Route>
        </Routes>
      </Router>

      <ToastContainer />
    </AuthProvider>
  );
}

function AuthWrapper({ children }) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  return children(user);
}

export default App;