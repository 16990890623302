import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { fetchOrders, fetchProducts, fetchClients } from '../../services/supabaseService';
import { supabase } from '../../supabaseConfig';

const RankingMedal = ({ position }) => {
  const medals = {
    1: { color: 'from-yellow-300 to-yellow-500', label: '' },
    2: { color: 'from-gray-300 to-gray-500', label: '' },
    3: { color: 'from-orange-300 to-orange-500', label: '' },
  };

  const medal = medals[position];
  if (!medal) return null;

  return (
    <div className="flex items-center space-x-2">
      <span className={`w-6 h-6 rounded-full bg-gradient-to-r ${medal.color}`} />
      <span className="text-sm font-medium">{medal.label}</span>
    </div>
  );
};

export default function RankingTable({ title, type }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          throw new Error("User is not authenticated");
        }
        const userId = session.user.id;

        if (type === 'products') {
          const orders = await fetchOrders(userId);
          const products = await fetchProducts();

          // Calcular las ventas de cada producto
          const productSales = {};
          orders.forEach(order => {
            order.productos.forEach(product => {
              if (!productSales[product.id]) {
                productSales[product.id] = {
                  id: product.id,
                  nombre: product.nombre,
                  imagen: product.imagen,
                  sales: 0,
                };
              }
              productSales[product.id].sales += product.cantidad;
            });
          });

          // Convertir a array y ordenar por ventas
          let rankedProducts = Object.values(productSales).sort((a, b) => b.sales - a.sales);

          // Completar el Top 5 con productos al azar si es necesario
          if (rankedProducts.length < 5) {
            const remainingProducts = products.filter(product => !productSales[product.id]);
            while (rankedProducts.length < 5 && remainingProducts.length > 0) {
              const randomIndex = Math.floor(Math.random() * remainingProducts.length);
              const randomProduct = remainingProducts.splice(randomIndex, 1)[0];
              rankedProducts.push({
                id: randomProduct.id,
                nombre: randomProduct.name,
                imagen: randomProduct.imageUrl,
                sales: 0,
              });
            }
          }

          setData(rankedProducts.slice(0, 5));
        } else if (type === 'clients') {
          const clients = await fetchClients(userId);
          const orders = await fetchOrders(userId);

          // Calcular el número de pedidos de cada cliente
          const clientOrders = {};
          orders.forEach(order => {
            if (!clientOrders[order.clienteId]) {
              const client = clients.find(c => c.id === order.clienteId);
              clientOrders[order.clienteId] = {
                id: order.clienteId,
                nombre: client ? client.nombre : 'Cliente desconocido',
                orderCount: 0,
              };
            }
            clientOrders[order.clienteId].orderCount += 1;
          });

          // Convertir a array y ordenar por número de pedidos
          let rankedClients = Object.values(clientOrders).sort((a, b) => b.orderCount - a.orderCount);

          // Completar el Top 5 con clientes al azar si es necesario
          if (rankedClients.length < 5) {
            const remainingClients = clients.filter(client => !clientOrders[client.id]);
            while (rankedClients.length < 5 && remainingClients.length > 0) {
              const randomIndex = Math.floor(Math.random() * remainingClients.length);
              const randomClient = remainingClients.splice(randomIndex, 1)[0];
              rankedClients.push({
                id: randomClient.id,
                nombre: randomClient.nombre,
                orderCount: 0,
              });
            }
          }

          setData(rankedClients.slice(0, 5));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setLoading(false);
      }
    };

    loadData();
  }, [type]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-xl overflow-hidden"
    >
      <div className="bg-gradient-to-r from-orange-400 to-orange-600 px-6 py-4">
        <h2 className="text-2xl font-bold text-white">{title}</h2>
      </div>
      <div className="p-4">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="border-b border-orange-100">
                <th className="px-4 py-3 text-left text-sm font-semibold text-orange-900">Posición</th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-orange-900">{type === 'products' ? 'Producto' : 'Cliente'}</th>
                <th className="px-4 py-3 text-right text-sm font-semibold text-orange-900">{type === 'products' ? 'Ventas' : 'Pedidos'}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <motion.tr
                  key={item.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: index * 0.1 }}
                  className="hover:bg-orange-50 transition-colors duration-150"
                >
                  <td className="px-4 py-3">
                    <RankingMedal position={index + 1} />
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center">
                      {type === 'products' ? (
                        <img
                          src={item.imagen || '/catalogo.jpg'}
                          alt={item.nombre}
                          className="h-10 w-10 rounded-lg object-cover mr-3"
                        />
                      ) : (
                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gradient-to-r from-orange-400 to-orange-600 text-white font-bold mr-3">
                          {item.nombre.charAt(0).toUpperCase()}
                        </span>
                      )}
                      <span className="font-medium text-gray-900">{item.nombre}</span>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-right font-medium">
                    {type === 'products' ? item.sales : item.orderCount}
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </motion.div>
  );
}