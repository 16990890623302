import React from "react";
import { motion } from "framer-motion";

const StatusDisplay = ({
  status,
  counter,
  NEQUI_NUMBER,
  handleRetry,
  handleLogout,
  onClose,
}) => {
  const handleContinue = () => {
    onClose();
    window.location.reload();
  };

  switch (status) {
    case "activo":
      return (
        <div className="text-center">
          <div className="bg-green-100 p-4 rounded-lg mb-4">
            <p className="text-green-700 text-xl">
              ¡Tu suscripción está activa!
            </p>
            <p className="text-green-600 mt-2">
              Ya puedes disfrutar de todas las funciones premium.
            </p>
          </div>
          <button
            onClick={handleContinue}
            className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition-colors duration-200"
          >
            Continuar
          </button>
        </div>
      );

    case "pendiente":
      return (
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-orange-500 mx-auto mb-4"></div>
          <p className="text-xl text-gray-700 mb-4">
            Esperando a que te activen la suscripción...
          </p>
          <p className="text-gray-600">
            Si tarda mucho, contáctanos por WhatsApp:
          </p>
          <a
            href={`https://wa.me/${NEQUI_NUMBER}`}
            className="text-orange-600 hover:text-orange-700"
          >
            {NEQUI_NUMBER}
          </a>
          <div className="text-lg font-semibold text-gray-700 mt-4">
            Verificando en {counter} segundos...
          </div>
        </div>
      );

    case "cancelado":
    case "rechazado":
      return (
        <div className="text-center">
          <p className="text-xl text-gray-700 mb-4">
            {status === "cancelado"
              ? "Ups, te cancelaron la suscripción por falta de pago."
              : "Tu suscripción fue rechazada. Por favor, intenta nuevamente."}
          </p>
          <p className="text-gray-600">
            Si tienes alguna pregunta, contáctanos por WhatsApp:
          </p>
          <a
            href={`https://wa.me/${NEQUI_NUMBER}`}
            className="text-orange-600 hover:text-orange-700"
          >
            {NEQUI_NUMBER}
          </a>
          <div className="space-y-4 mt-4">
            <button
              onClick={() => {
                handleRetry();
                window.location.reload();
              }}
              className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition-colors duration-200"
            >
              {status === "cancelado" ? "Renovar" : "Intentar Nuevamente"}
            </button>
            <button
              onClick={handleLogout}
              className="w-full bg-gray-100 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200"
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default StatusDisplay;