  // src/context/AuthContext.js
  import React, { createContext, useContext, useEffect, useState } from 'react';
  import { supabase } from '../supabaseConfig';
  
  const AuthContext = createContext();
  
  export const useAuth = () => useContext(AuthContext);
  
  export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
  
    useEffect(() => {
      const getSession = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        setCurrentUser(session ? session.user : null);
      };
    
      getSession();
    
      const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
        setCurrentUser(session ? session.user : null);
      });
    
      return () => {
        authListener.subscription.unsubscribe();
      };
    }, []);
  
    return (
      <AuthContext.Provider value={{ currentUser }}>
        {children}
      </AuthContext.Provider>
    );
  };